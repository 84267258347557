var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" 前回カラー ")]),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":"前後1時間","hide-details":""},model:{value:(_vm.filterTime),callback:function ($$v) {_vm.filterTime=$$v},expression:"filterTime"}})],1),_c('v-dialog',{model:{value:(_vm.dialog.visible),callback:function ($$v) {_vm.$set(_vm.dialog, "visible", $$v)},expression:"dialog.visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 施術履歴 "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('OperationHistoryTable',{attrs:{"customer":_vm.dialog.customer,"height":"600px","hide-staffs":""}})],1)],1)],1),_c('v-data-table',{staticClass:"table",attrs:{"items":_vm.items,"headers":_vm.headers,"items-per-page":_vm.items.length,"loading":_vm.isLoading,"hide-default-footer":""},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullNameKana)+" "),_c('br'),_vm._v(" ("+_vm._s(item.customerCode)+") ")]}},{key:"item.dyestuffRoot",fn:function(ref){
var item = ref.item;
return [(item.prevOperationHistory)?_c('UsedDyestuffs',{attrs:{"dyestuffs":item.prevOperationHistory.usedDyestuffRoots,"solutions":item.prevOperationHistory.usedSolutionRoots,"dyestuff-amount":item.prevOperationHistory.dyestuffAmountRoot,"bold":""}}):_vm._e()]}},{key:"item.dyestuffEnd",fn:function(ref){
var item = ref.item;
return [(item.prevOperationHistory)?_c('UsedDyestuffs',{attrs:{"dyestuffs":item.prevOperationHistory.usedDyestuffEnds,"solutions":item.prevOperationHistory.usedSolutionEnds,"dyestuff-amount":item.prevOperationHistory.dyestuffAmountEnd,"bold":""}}):_vm._e()]}},{key:"item.previousReservedDate",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(value)?[_vm._v(" "+_vm._s(_vm.deltaDays(item.date, value))+"日前 ")]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }